import Grid from "@components/Grid/Grid";
import { themr } from "@friendsofreactjs/react-css-themr";
import Link from "@components/Link/Link";
import { GeneralLink } from "../../types";
import Cta from "../Cta/Cta";
import Image from "../Image/Image";
import styles from "./C02_Footer.module.scss";
import { useRouter } from "next/router";
import setGADataLayer, { dataLayerEventKey } from "@utilities/setGADataLayer";

export interface FooterProps {
  primaryLinksColumn1: GeneralLink[];
  primaryLinksColumn2: GeneralLink[];
  primaryLinksColumn3: GeneralLink[];
  secondaryLinks: GeneralLink[];
  ctaHeading: string;
  ctaText: string;
  ctaLink: string;
  socialHeading: string;
  socialLinks: any;
  copyrightText: string;
}

export const Footer = ({
  content,
  theme,
}: {
  content: FooterProps;
  theme?: any;
}) => {
  const {
    primaryLinksColumn1,
    primaryLinksColumn2,
    primaryLinksColumn3,
    secondaryLinks,
    ctaHeading,
    ctaText,
    ctaLink,
    socialHeading,
    socialLinks,
    copyrightText,
  } = content;

  const router = useRouter();

  let pageURL = router?.asPath;
  pageURL = pageURL === "/" ? pageURL.replace("/", "") : pageURL;

  const setGAClick = (name: string, destination: string) => {
    setGADataLayer({
      data: {
        name: name,
        referring_page:
          typeof window !== "undefined" ? window.location.href : "",
        destination_page: destination,
      },
      _clear: true,
      event: dataLayerEventKey.FOOTER,
    });
  };

  const NavLink = ({ link }) => (
    <li>
      <Link href={link.fields.url}>
        <a onClick={() => setGAClick(link.fields.linkText, link.fields.url)}>
          {link.fields.linkText}
        </a>
      </Link>
    </li>
  );

  return (
    <footer className={theme["site-footer"]}>
      <div className="wrapper">
        <div className={theme.top}>
          <Grid row>
            <Grid column sm={12} md={3}>
              <ul>
                {primaryLinksColumn1?.map((link, i) => (
                  <NavLink link={link} key={`${i}${link.fields?.linkText}`} />
                ))}
              </ul>
            </Grid>
            <Grid column sm={12} md={3}>
              <ul>
                {primaryLinksColumn2?.map((link, i) => (
                  <NavLink link={link} key={`${i}${link.fields?.linkText}`} />
                ))}
              </ul>
            </Grid>
            <Grid column sm={12} md={3}>
              <ul>
                {primaryLinksColumn3?.map((link, i) => (
                  <NavLink link={link} key={`${i}${link.fields?.linkText}`} />
                ))}
              </ul>
            </Grid>
            <Grid column sm={12} md={3}>
              <div className={theme.ctas}>
                <p>{ctaHeading}</p>
                <Cta
                  type="secondary"
                  light={true}
                  url={pageURL?.split("?")[0].split("#")[0] + ctaLink}
                  linkText={ctaText}
                  onClick={() =>
                    setGAClick(
                      ctaText,
                      pageURL?.split("?")[0].split("#")[0] + ctaLink
                    )
                  }
                />
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={theme.social}>
          <Grid row>
            <Grid column sm={12}>
              <p className={`${theme.large} large`}>{socialHeading}</p>
            </Grid>
            <Grid column sm={12}>
              <ul>
                {socialLinks.map(({ fields }, i) => {
                  return (
                    <li key={`${i}${fields.title}`}>
                      <a
                        href={fields.url}
                        aria-label={fields.title}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() =>
                          setGADataLayer({
                            data: {
                              platform: fields.title,
                            },
                            _clear: true,
                            event: dataLayerEventKey.SOCIAL_CLICKOUT,
                          })
                        }
                      >
                        <div className={`${theme.socialIcon} image-hover`}>
                          <Image
                            src={fields.icon.fields.file.url}
                            alt={fields.title}
                            width={40}
                            height={40}
                          />
                        </div>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </Grid>
          </Grid>
        </div>
        <div className={theme.bottom}>
          <Grid row>
            <Grid column md={9} sm={12}>
              <ul>
                {secondaryLinks.map((link, i) => (
                  <NavLink link={link} key={`${i}${link.fields?.linkText}`} />
                ))}
              </ul>
            </Grid>
            <Grid column md={3} sm={12}>
              <div className={theme.copyright}>
                <p>{copyrightText}</p>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </footer>
  );
};

export default themr("Footer", styles)(Footer);
